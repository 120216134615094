// TypingAnimation.js
import { useEffect, useState } from "react";
import { cn } from "../../lib/utils";

export function TypingAnimation({
  text = "",
  strongText = "",
  duration = 200,
  className,
}) {
  const [displayedText, setDisplayedText] = useState("");
  const [i, setI] = useState(0);

  useEffect(() => {
    const combinedText = `${text} ${strongText}`;
    const typingEffect = setInterval(() => {
      if (i < combinedText.length) {
        setDisplayedText(combinedText.substring(0, i + 1));
        setI(i + 1);
      } else {
        clearInterval(typingEffect);
      }
    }, duration);

    return () => clearInterval(typingEffect);
  }, [duration, i, text, strongText]);

  const textPart = displayedText.slice(0, text.length);
  const strongPart = displayedText.slice(text.length);

  return (
    <h1>
      {textPart}
      {strongPart && <strong>{strongPart}</strong>}
    </h1>
  );
}
