import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import IconHome from '../Icons/IconHome'
import "./style.css"
import IconClose from '../Icons/IconClose';

export default function Navigation({ isDarkMode, onMenuClick }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className={`${isDarkMode ? 'lightnav' : 'darknav'}`}>
       {isMobile && (
        <div className='PhoneMenuOpen' onClick={toggleMenu}>
         {isMenuOpen ?  <IconClose/>: <IconHome /> }
        </div>
      )}
         <ul className={`menu ${isMenuOpen ? 'open' : ''}`}>
         {isMobile && (
         <li>
         <NavLink 
           to="/" 
           onClick={onMenuClick}
           className={({ isActive }) => (isActive ? 'active' : '')}
         >
           Home
         </NavLink>
       </li>
      )}
          {/* <li>
            <NavLink 
              to="/about-us" 
              onClick={onMenuClick}
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink 
              to="/features"
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              Features
            </NavLink>
          </li> */}
          <li className='desktoponly'>
            <NavLink 
              to="/" 
              onClick={onMenuClick}
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <IconHome />
            </NavLink>
          </li>
          {/* <li>
            <NavLink 
              to="/careers"
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              Careers
            </NavLink>
          </li>
          <li>
            <NavLink 
              to="/contact-us"
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              Contact Us
            </NavLink>
          </li> */}
        </ul>   

    </nav>
  )
}
