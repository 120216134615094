import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../../pages/Home'

import Features from '../../pages/Features'
import Careers from '../../pages/Careers'
import ContactUs from '../../pages/ContactUs'
import { AboutUs } from '../../pages/AboutUs'

export default function NavRouting() {
  return (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />  
        <Route path="/features" element={<Features />} />  
        <Route path="/careers" element={<Careers />} />  
        <Route path="/contact-us" element={<ContactUs />} />  
        
    </Routes>
  )
}
