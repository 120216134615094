import React from "react";

export default function IconEmail() {
  return (
    <svg
      enableBackground="new 0 0 511.998 511.998"
      height="20"
      viewBox="0 0 511.998 511.998"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m511.998 111.383c0-25.98-21.137-47.117-47.117-47.117h-417.764c-25.975.001-47.108 21.128-47.117 47.125v.008 289.199c0 26.305 21.352 47.133 47.133 47.133h417.731c26.304 0 47.133-21.351 47.133-47.133v-289.199c0-.002 0-.004 0-.006.001-.003.001-.006.001-.01zm-464.881-17.116h417.764c9.438 0 17.117 7.679 17.117 17.141 0 5.028-2.499 9.695-6.689 12.487l-209.805 139.876c-5.773 3.849-13.235 3.85-19.009 0 0 0-209.81-139.879-209.806-139.876.001.001-.003-.002-.004-.003-4.186-2.789-6.685-7.456-6.685-12.509 0-9.438 7.679-17.116 17.117-17.116zm417.748 323.464h-417.732c-9.339 0-17.133-7.551-17.133-17.133v-245.106l199.853 133.239c7.942 5.295 17.044 7.942 26.146 7.942 9.103 0 18.206-2.647 26.147-7.942l199.852-133.239v245.107c0 9.338-7.551 17.132-17.133 17.132z"></path>
    </svg>
  );
}
