import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import $ from 'jquery';
import 'tilt.js';
import './style.css';

export default function Features() {
  const tiltRefs = useRef([]);
  const location = useLocation();
  const [reloadKey, setReloadKey] = useState(0);

  useEffect(() => {
    // Force reinitialize by updating `reloadKey` on navigation
    setReloadKey((prev) => prev + 1);
  }, [location.pathname]); // This triggers on every navigation to this page

  useEffect(() => {
    tiltRefs.current.forEach((element) => {
      if (element) {
        $(element).tilt({
          maxTilt: 25,
          perspective: 1000,
          easing: "cubic-bezier(.03,.98,.52,.99)",
          scale: 1.1,
          speed: 400,
          transition: true,
          'max-glare': 0.1,
        });
      }
    });

    return () => {
      tiltRefs.current.forEach((element) => {
        if (element) {
          $(element).tilt('destroy');
        }
      });
    };
  }, [reloadKey]); // Re-run tilt effect initialization on reloadKey change

  return (
    
      <section className="features-main">
        <div className="container">
          <div className="section-main">
          <h1 className='pagetitle'><strong>Features</strong></h1>
          <div className="features-boxmain">          
            <div className="tilt-box" ref={(el) => tiltRefs.current[0] = el}>
              <img src='../images/aggrigation.svg' className='lightpic' alt='Aggregation of Data'/>
              <img src='../images/aggrigationblack.svg' className='blackpic' alt='Aggregation of Data'/>
              <h3>Aggregation of Data</h3>
            </div>
            <div className="tilt-box" ref={(el) => tiltRefs.current[1] = el}>
              <img src='../images/dimensionview.png' className='lightpic' alt='Three dimension view'/>
              <img src='../images/dimensionviewblack.png' className='blackpic' alt='Three dimension view'/>
              <h3>Regular Update</h3>
            </div>
            <div className="tilt-box" ref={(el) => tiltRefs.current[2] = el}>
              <img src='../images/regularupdate.svg' alt='Regular Update'/>                             
              <h3>Three dimension view</h3>             
            </div>
          </div>
        </div>
        </div>
      </section>
    
  );
}
