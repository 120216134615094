import React from "react";

export default function IconHome() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0702 7.39418C16.0698 7.39379 16.0695 7.3934 16.0691 7.39301L9.54171 0.458618C9.26348 0.162903 8.89357 0 8.5001 0C8.10663 0 7.73672 0.162773 7.45837 0.458488L0.934387 7.38938C0.932189 7.39172 0.929992 7.39418 0.927794 7.39651C0.356447 8.00701 0.357424 8.99753 0.930602 9.60647C1.19247 9.8848 1.53833 10.046 1.90812 10.0629C1.92314 10.0644 1.93827 10.0652 1.95353 10.0652H2.21369V15.1685C2.21369 16.1784 2.98709 17 3.93787 17H6.49159C6.75041 17 6.96039 16.777 6.96039 16.502V12.501C6.96039 12.0402 7.31321 11.6653 7.74697 11.6653H9.25323C9.68699 11.6653 10.0398 12.0402 10.0398 12.501V16.502C10.0398 16.777 10.2497 17 10.5086 17H13.0623C14.0131 17 14.7865 16.1784 14.7865 15.1685V10.0652H15.0277C15.4211 10.0652 15.791 9.90244 16.0695 9.60673C16.6433 8.99675 16.6435 8.00455 16.0702 7.39418Z"
        fill="#00356B"
      />
    </svg>
  );
}
