import React, { forwardRef, useRef } from "react";
import { AnimatedBeam } from "../../component/AnimatedBeam/AnimatedBeam";
import { BorderBeam } from '../../component/BorderBeam/BorderBeam';
import Debt from '../../component/Icons/abouticons/Debt';
import Equity from "../../component/Icons/abouticons/Equity";
import MutualFunds from "../../component/Icons/abouticons/MutualFunds";
import OIS from '../../component/Icons/abouticons/OIS';
import UserIcon from '../../component/Icons/abouticons/UserIcon';
import LogoBlack from '../../component/Icons/LogoBlack';
import { cn } from "../../lib/utils";

const Circle = forwardRef(({ className, children }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "z-10 gap-2 flex flex-col text-center items-center justify-center rounded-xl max-xl:w-32 max-xl:h-20 w-28 h-24 bg-white p-2 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)]",
        className,
      )}
    >
      {children}
    </div>
  );
});

Circle.displayName = "Circle";

export function AboutUs() {
  const containerRef = useRef(null);
  const div1Ref = useRef(null);
  const div2Ref = useRef(null);
  const div3Ref = useRef(null);
  const div4Ref = useRef(null);
  const div5Ref = useRef(null);
  const div6Ref = useRef(null); 

  return (
           <section className="about-main">
       <div className="container">
       <div className="section-main relative overflow-hidden">
          <h1 className="pagetitle"><strong>Data Flow</strong></h1>        
    <div
      className="relative max-xxl:gap-[0px] gap-[100px] flex-col flex w-full items-center justify-center overflow-hidden"
      ref={containerRef}
    >
      <div className="flex size-full flex-col items-stretch justify-between max-xxl:gap-10 gap-20">
        <div className="flex svgicons flex-row items-center justify-between">
          <Circle ref={div1Ref}>
          <Equity/>
          <p className="font-semibold max-xl:text-[10px] text-[11px] leading-3">Equity</p> 
          </Circle>
          <Circle ref={div3Ref}>
            <MutualFunds/>            
            <p className="font-semibold max-xl:text-[10px] text-[11px] leading-3">Mutual Funds</p>
          </Circle>
        </div>
        <div className="flex svgicons flex-row items-center justify-between">
          <Circle ref={div2Ref}>
          <Debt/> 
          <p className="font-semibold max-xl:text-[10px] text-[11px] leading-3">Debt</p>            
          </Circle>
          <Circle ref={div4Ref} className="size-24 -mt-48">
          <LogoBlack/>           
          </Circle>
          <Circle ref={div5Ref}>
          <OIS/>
          <p className="font-semibold max-xl:text-[10px] text-[11px] leading-3">Other Investments</p>            
          </Circle>
        </div>        
      </div>
      
      <div className="flex svgicons flex-col justify-center">
          <Circle ref={div6Ref}>
          <UserIcon/>
          <p className="font-semibold text-[11px] leading-3">Users</p>            
          </Circle>
        </div>
{/* 1. */}
      <AnimatedBeam 
        containerRef={containerRef}
        fromRef={div1Ref}
        toRef={div4Ref}
        curvature={60}
        endYOffset={-10}
      />
      {/* 2. */}
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div2Ref}
        toRef={div4Ref}
        curvature={-75}
        endYOffset={10}
      />
      {/* 3. MF */}
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div3Ref}
        toRef={div4Ref}
        curvature={75}
        endYOffset={-2}
        reverse
      />
      {/* 5. Other Invces */}
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div5Ref}
        toRef={div4Ref}
        curvature={-50}
        endYOffset={20}
        reverse
      />

      {/* 6. */}
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div4Ref}
        toRef={div6Ref}
        curvature={75}
        endYOffset={10}        
        // duration={3}
        // startYOffset={-10}        
        //curvature={20}
        //reverse
        // delay={1.0}
        // duration={1.5}
      />
    </div>
    <BorderBeam size={250} duration={12} delay={9} />    
    </div>
    </div>
    </section>
    
  );
}

