export const JobData = [
    {
      id: 1,
      title: "Business Analyst",      
      location:"Location: Ahmedabad, India",
      describe:"We are seeking a skilled Business Analyst with a strong background in the Finance and Investment domain to join our dynamic team. The ideal candidate will be responsible for doing research of publicly available data, analyzing complex financial data, identifying trends, and providing actionable insights to support strategic decision-making across the organization. Candidate will also be responsible for development of Product.",
      requirements:"Requirements & Qualifications",
      point1:"Bachelor’s degree in Engineering / Finance / Administration, or related field",
      point2:"Master’s degree preferred.",
      point3:"Proven experience of atleast 2 years as a Business Analyst in the Finance domain",
      point4:"Strong understanding of financial markets, products, and instruments (e.g., equities, fixed income, derivatives)",
      point5:"Excellent analytical and problem-solving skills with a keen attention to detail.",
      point6:"Advanced proficiency in Microsoft Excel, financial software, and data visualization tools.",
      point7:"Ability to communicate complex ideas effectively and influence stakeholders at all levels.",
      responsibilities:"Key Responsibilities",
      key1:"Collaborate with stakeholders to define project scope, objectives, and deliverables.",
      key2:"Strong attention to detail and ability to maintain accuracy in data capturing.", 
      key3:"Evaluate existing business processes and identify areas for improvement or optimization.", 
      key4:"Prepare comprehensive reports and presentations for senior management and key stakeholders. - Support cross-functional teams in implementing strategic initiatives and projects.", 
      key5:"Stay updated on industry trends, regulations, and best practices to ensure compliance and competitive advantage.", 
      key6:"Advanced proficiency in Microsoft Excel, financial software, and data visualization tools.", 
    },
    {
      id: 2,
      title: "PHP Developer",
      location:"Location: Ahmedabad, India",
      describe:"We are seeking a skilled Business Analyst with a strong background in the Finance and Investment domain to join our dynamic team. The ideal candidate will be responsible for doing research of publicly available data, analyzing complex financial data, identifying trends, and providing actionable insights to support strategic decision-making across the organization. Candidate will also be responsible for development of Product.",
    },
    {
      id: 3,
      title: ".Net Developer",
      location:"Location: Ahmedabad, India",
      describe:"We are seeking a skilled Business Analyst with a strong background in the Finance and Investment domain to join our dynamic team. The ideal candidate will be responsible for doing research of publicly available data, analyzing complex financial data, identifying trends, and providing actionable insights to support strategic decision-making across the organization. Candidate will also be responsible for development of Product.",
    },
    {
      id: 4,
      title: "Android Developer",
      location:"Location: Ahmedabad, India",
      describe:"We are seeking a skilled Business Analyst with a strong background in the Finance and Investment domain to join our dynamic team. The ideal candidate will be responsible for doing research of publicly available data, analyzing complex financial data, identifying trends, and providing actionable insights to support strategic decision-making across the organization. Candidate will also be responsible for development of Product.",
    }
  ];