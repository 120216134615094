import React from "react";
import { BorderBeam } from "../../component/BorderBeam/BorderBeam";
import { TypingAnimation } from "../../component/TypingAnimation/TypingAnimation";
import "./style.css";

export default function Home() {
  return (
    
      <section className="home-main">
        <div className="container">
        <div className="section-main relative overflow-hidden">
          <div className="home-inner">
            <div className="welcomecontentbox">            
            <TypingAnimation
              text="Welcome to"
              strongText="ONE CASE"
              duration={100}              
            />
              {/* <h1>
                Welcome to <strong>ONE CASE</strong>
              </h1> */}
              <p>
                We are excited to unveil an innovative financial advisory
                platform in India, crafted to enhance the investment journey for
                both individuals and businesses. Utilizing advanced technology,
                our platform offers personalized insights and strategies,
                enabling clients to make confident, well-informed financial
                choices. Join us as we revolutionize the future of investment
                advisory.
              </p>
            </div>
            <picture>
              <img src="../images/slider-pic.png" alt="Welcome to ONE CASE" />
            </picture>            
          </div>
          <BorderBeam size={250} duration={12} delay={9} />
          </div>
        </div>
      </section>
    
  );
}
