import React, { useRef } from 'react';
import Header from '../Header';
import Navigation from '../Navigation';

export default function PageLayout({ children, toggleTheme, isDarkMode }) {
  const contentRef = useRef(null);

  const scrollToContent = () => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className="mainlayout">
      <div className="thememain">
        <Header toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
        <div className="content-main" ref={contentRef}>
          {children}
        </div>
        <Navigation isDarkMode={isDarkMode} onMenuClick={scrollToContent} />
      </div>
    </div>
  );
}
