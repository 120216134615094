import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import '../src/assest/css/style.css';
import NavRouting from './component/NavRouting';
import PageLayout from './component/PageLayout';
import Particles from './component/Particles/MousePosition';

function App() {
  const [isDarkMode, setIsDarkMode] = useState(null); // Manage theme state here

  // Handle the toggle change
  const toggleTheme = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem('isDarkMode', newTheme);
  };

  // Initialize the theme state on mount
  useEffect(() => {
    const savedTheme = localStorage.getItem('isDarkMode');
    if (savedTheme !== null) {
      setIsDarkMode(savedTheme === 'true'); // Parse the saved value to boolean
    } else {
      // Default theme if no theme is found in localStorage
      setIsDarkMode(false);
    }
  }, []);

  useEffect(() => {
    // Only proceed if `isDarkMode` is not null
    if (isDarkMode !== null) {
      // Apply theme to body
      if (isDarkMode) {
        document.body.classList.add('app-light');
        document.body.classList.remove('app-dark');
      } else {
        document.body.classList.add('app-dark');
        document.body.classList.remove('app-light');
      }
    }
  }, [isDarkMode]);

  return (
    <div className="app">
      <BrowserRouter>
        <PageLayout toggleTheme={toggleTheme} isDarkMode={isDarkMode}>
          <NavRouting/>
        </PageLayout>
      </BrowserRouter>
      <Particles
        className="absolute z-[-1] inset-0"
        quantity={400}
        ease={80}
        color={isDarkMode ? '#000' : "#fff"}
        refresh
      />
    </div>
  );
}

export default App;
