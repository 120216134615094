import React from "react";
import IconLinkdin from "../../component/Icons/IconLinkdin";
import IconEmail from "../../component/Icons/IconEmail";
import './style.css'
import { Link } from "react-router-dom";

export default function ContactUs() {
  return (
      <section className="contact-main">
        <div className="container">
        <div className="section-main">
          <div className="contact-page">
        <h1 className='pagetitle'><strong>Contact Us</strong></h1>
        <div className="contactinformation">        
       <Link to="#" className="nowcontact" data-tooltip="Email">
        <IconEmail/>
        </Link>
        <Link to="#" className="nowcontact" data-tooltip="LinkedIn">
        <IconLinkdin/>
        </Link>
        </div>   
        </div>
        </div>
        </div>
      </section>    
  );
}
