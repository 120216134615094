import React from 'react'

export default function FileUpload() {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#F0F6FF"/>
<rect x="11.5" y="11.5" width="37" height="37" rx="18.5" stroke="url(#paint0_linear_422_2612)" strokeWidth="3"/>
<path fillRule="evenodd" clipRule="evenodd" d="M25.368 26.6099C25.782 26.6099 26.118 26.9373 26.118 27.3406C26.118 27.744 25.782 28.0713 25.368 28.0713H24.435C22.816 28.0713 21.5 29.3534 21.5 30.9298V35.6792C21.5 37.2565 22.816 38.5386 24.435 38.5386H35.565C37.183 38.5386 38.5 37.2565 38.5 35.6792V30.921C38.5 29.3495 37.188 28.0713 35.576 28.0713H34.633C34.219 28.0713 33.883 27.744 33.883 27.3406C33.883 26.9373 34.219 26.6099 34.633 26.6099H35.576C38.015 26.6099 40 28.5438 40 30.921V35.6792C40 38.0622 38.01 40 35.565 40H24.435C21.99 40 20 38.0622 20 35.6792V30.9298C20 28.5477 21.99 26.6099 24.435 26.6099H25.368ZM30.5306 20.2068L33.4466 23.0594C33.7386 23.3458 33.7376 23.8076 33.4446 24.0921C33.1506 24.3766 32.6766 24.3766 32.3846 24.0901L30.749 22.4912L30.7496 33.1831H29.2496L29.249 22.4912L27.6156 24.0901C27.4696 24.2343 27.2766 24.3054 27.0846 24.3054C26.8936 24.3054 26.7016 24.2343 26.5556 24.0921C26.2626 23.8076 26.2606 23.3458 26.5536 23.0594L29.4686 20.2068C29.7496 19.9311 30.2496 19.9311 30.5306 20.2068Z" fill="url(#paint1_linear_422_2612)"/>
<defs>
<linearGradient id="paint0_linear_422_2612" x1="11" y1="35.5" x2="46.1791" y2="41.3792" gradientUnits="userSpaceOnUse">
<stop stopColor="#309886"/>
<stop offset="1" stopColor="#1C5DD9"/>
</linearGradient>
<linearGradient id="paint1_linear_422_2612" x1="20" y1="35" x2="27.7879" y2="33.7367" gradientUnits="userSpaceOnUse">
<stop stopColor="#309886"/>
<stop offset="1" stopColor="#1C5DD9"/>
</linearGradient>
</defs>
</svg>

  )
}
