import React, { useEffect, useRef, useState } from "react";
import FileUpload from "../../component/Icons/FileUpload";
import IconChevronDown from "../Icons/IconChevronDown";

export default function CareersForm({jobTitle}) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [file, setFile] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const inputRef = useRef(null);
  const validExtensions = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const swiperRef = useRef(null);

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && isValidFileType(selectedFile)) {
      setFile(selectedFile);
      setUploadMessage("File ready to upload");
    } else {
      setUploadMessage("Please upload only .docx or .pdf files");
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = () => {
    setDragActive(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragActive(false);
    const selectedFile = event.dataTransfer.files[0];
    if (selectedFile && isValidFileType(selectedFile)) {
      setFile(selectedFile);
      setUploadMessage("File ready to upload");
    } else {
      setUploadMessage("Please upload only .docx or .pdf files");
    }
  };

  const isValidFileType = (file) => {
    return validExtensions.includes(file.type);
  };

  const handleUpload = () => {
    if (file) {
      setUploadMessage("Upload successful!");
    } else {
      setUploadMessage("No file selected");
    }
  };

  // Slide navigation functions
  const goToSlide = (index) => {
    setCurrentSlide(index);
  };
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent form submit or page reload
  };
  const handleScroll = (event) => {
    if (event.deltaY > 0) {
      // Scroll down, move to next slide
      if (currentSlide < 1) setCurrentSlide(currentSlide + 1);
    } else {
      // Scroll up, move to previous slide
      if (currentSlide > 0) setCurrentSlide(currentSlide - 1);
    }
  };

  useEffect(() => {
    window.addEventListener("wheel", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, [currentSlide]);

  return (
    <form onSubmit={handleSubmit} className="form-main section-main">      
      <div className="slides-container">
        <div
          className={`slide ${currentSlide === 0 ? "active" : ""} ${
            currentSlide === 1 ? "previous" : ""
          }`}          
        >         
          <div className="forminner">   
          <div className="pagetitlesubtitle">
          <h1 className='pagetitle'><strong>Careers</strong></h1> <IconChevronDown/> <h2>{jobTitle}</h2> <IconChevronDown/> 
          <p>Apply Here</p>
          </div>       
            <div className="candidate-main firstfield">
              <div className="candidate">
                <label htmlFor="name">Name of Candidate:</label>
                <input type="text" name="name" placeholder="Write Here" />
              </div>
            </div>

            <div className="candidate-main">
              <div className="candidate">
                <label htmlFor="totalExperience">
                  Total Experience/
                  <br />
                  Relevant Experience
                </label>
                <input
                  type="text"
                  placeholder="Write Here"
                  name="totalExperience"
                />
              </div>
              <div className="candidate">
                <label htmlFor="CurrentLocation">Current Location</label>
                <input
                  type="text"
                  placeholder="Write Here"
                  name="CurrentLocation"
                />
              </div>
            </div>

            <div className="candidate-main">
              <div className="candidate">
                <label htmlFor="qualification">Educational Qualification</label>
                <input
                  type="text"
                  placeholder="Write Here"
                  name="qualification"
                />
              </div>
              <div className="candidate">
                <label htmlFor="relocation">
                  Ready for Relocation? If applicable.
                </label>
                <input type="text" name="relocation" placeholder="Write Here" />
              </div>
            </div>
            <div className="candidate-main">
              <div className="candidate">
                <label htmlFor="currentcompany">Current Company</label>
                <input
                  type="text"
                  placeholder="Write Here"
                  name="currentcompany"
                />
              </div>
              <div className="candidate">
                <label htmlFor="currentctc">Current CTC</label>
                <input type="text" name="currentctc" placeholder="Write Here" />
              </div>
            </div>
            <div className="candidate-main">
              <div className="candidate">
                <label htmlFor="currentdesignation">Current Designation</label>
                <input
                  type="text"
                  name="currentdesignation"
                  placeholder="Write Here"
                />
              </div>
              <div className="candidate">
                <label htmlFor="expectedctc">Expected CTC</label>
                <input
                  type="text"
                  name="expectedctc"
                  placeholder="Write Here"
                />
              </div>
            </div>
            <div className="candidate-main">
              <div className="candidate">
                <label htmlFor="reasonforchange">Reason for change</label>
                <input
                  type="text"
                  name="reasonforchange"
                  placeholder="Write Here"
                />
              </div>
              <div className="candidate">
                <label htmlFor="noticeperiod">Notice Period</label>
                <input
                  type="text"
                  name="noticeperiod"
                  placeholder="Write Here"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Slide 2 */}
        <div
          className={`slide ${currentSlide === 1 ? "active" : ""} ${
            currentSlide === 0 ? "previous" : ""
          }`}
        >
          <div className="forminner">
            <div className="uploadtitle">
              <h5>Upload Your Resume Here</h5>
              <p>Please Upload only: Docx. or PDF Files</p>
            </div>

            <div
              className={`drag-area ${dragActive ? "active" : ""}`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <div className="icondropbox">
                <FileUpload />
              </div>
              <p>Drag and Drop here</p>
              <span>Or</span>
              <button onClick={handleButtonClick}>Browse File</button>
              <input
                type="file"
                ref={inputRef}
                onChange={handleFileChange}
                hidden
                accept=".pdf, .docx"
              />
            </div>
            <button className="uploadbtn btntwo" onClick={handleUpload}>
              Upload
            </button>
            {uploadMessage && (
              <p style={{ textAlign: "center", fontSize: 10, marginTop: 5 }}>
                {uploadMessage}
              </p>
            )}
            <div className="submite-main">
              <button className="cancenbtn btntwo">Cancel</button>
              <button className="submitbtn btntwo">Submit</button>
            </div>
          </div>
        </div>
      </div>

      <div className="pagination">
        <button
          type="button"
          onClick={() => goToSlide(0)}
          className={`pagination-button ${currentSlide === 0 ? "active" : ""}`}
        ></button>
        <button
          type="button"
          onClick={() => goToSlide(1)}
          className={`pagination-button ${currentSlide === 1 ? "active" : ""}`}
        ></button>
      </div>
    </form>
  );
}
