import React, { useState } from "react";
import "./style.css";
import JobOpening from "../../component/CareersForm/JobOpening";
import CareersForm from "../../component/CareersForm";

export default function Careers() {
  const [showJobOpening, setShowJobOpening] = useState(true); 
  const [selectedJobTitle, setSelectedJobTitle] = useState("");
  // Handle Apply Now click to hide JobOpening and show CareersForm
  const handleApplyClick = () => {
    setShowJobOpening(false); // Hide JobOpening and show CareersForm
  };
  const handleJobTitleClick = (title) => {
    setSelectedJobTitle(title); // Set the selected job title
  };

  return (
    
      <section className="careers-main">
        <div className="container">
        {showJobOpening ? (
            <JobOpening onApplyClick={handleApplyClick} onJobTitleClick={handleJobTitleClick} />
          ) : (
            <CareersForm jobTitle={selectedJobTitle} />
          )}  
         </div>
      </section>
    
  );
}
